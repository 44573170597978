import React, { useEffect } from 'react';
import { Layout, Form, Input, Row, Col, Radio } from 'antd';

const Bateria = ({
    form,
    onSave,
    useSameAddress,
    setUseSameAddress,
    handleCepChange,
    handleNumeroChange
}) => {
    const handleSaveForm = () => form.getFieldsValue(); // Captura os valores do formulário

    useEffect(() => {
        if (onSave) {
            onSave(handleSaveForm); // Define a função onSave
        }
    }, [onSave]);
    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>

            <Form layout="vertical" form={form}>
                <h2>Bateria</h2>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item label="Nome do Gerente" name="nomeGerente" rules={[{ required: true, message: 'Informe o nome do gerente!' }]}>
                            <Input placeholder="Nome do Gerente" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Email do Gerente" name="emailGerente" rules={[{ required: true, message: 'Informe o email!' }]}>
                            <Input placeholder="Digite o Email" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Telefone ou Celular" name="telefone" rules={[{ required: true, message: 'Informe o telefone!' }]}>
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="O Endereço é o mesmo do site?" name="mesmoEndereco">
                    <Radio.Group onChange={(e) => setUseSameAddress(e.target.value === 'sim')} defaultValue="sim">
                        <Radio value="sim">Sim</Radio>
                        <Radio value="nao">Não</Radio>
                    </Radio.Group>
                </Form.Item>

                {/* Exibe os campos de endereço apenas se não for o mesmo do site */}
                {!useSameAddress && (
                    <>
                        <Row gutter={12} align="bottom">
                            <Col span={4}>
                                <Form.Item label="CEP" name="cep" rules={[{ required: true, message: 'Informe o CEP!' }]}>
                                    <Input placeholder="Digite o CEP" onChange={(e) => handleCepChange(e.target.value)} />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Rua" name="rua" rules={[{ required: true, message: 'Informe a rua!' }]}>
                                    <Input placeholder="Nome da Rua" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Bairro" name="bairro" rules={[{ required: true, message: 'Informe o bairro!' }]}>
                                    <Input placeholder="Nome do Bairro" />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item label="Complemento" name="complemento">
                                    <Input placeholder="Digite o Complemento" />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item label="Nº" name="numero" rules={[{ required: true, message: 'Informe o número!' }]}>
                                    <Input placeholder="Digite o Nº" onChange={(e) => handleNumeroChange(e.target.value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )}

                <h2>Informações da Bateria</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item label="Capacidade da Bateria (kWh)" name="capacidade" rules={[{ required: true, message: 'Informe a capacidade!' }]}>
                            <Input placeholder="Digite o Nº" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Taxa de descarga da Bateria" name="taxaDescarga" rules={[{ required: true, message: 'Informe a taxa!' }]}>
                            <Input placeholder="Digite a Taxa" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Densidade Energética" name="densidadeEnergetica" rules={[{ required: true, message: 'Informe a taxa!' }]}>
                            <Input placeholder="Digite o Nº" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item label="Tensão da Bateria (kWh)" name="tensao" rules={[{ required: true, message: 'Informe a capacidade!' }]}>
                            <Input placeholder="Digite o Nº" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Eficiência Energética" name="eficiencia" rules={[{ required: true, message: 'Informe a taxa!' }]}>
                            <Input placeholder="Digite a Eficiência" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Tempo de Recarga" name="recarga" rules={[{ required: true, message: 'Informe a taxa!' }]}>
                            <Input placeholder="Digite o Nº" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Bateria;
