import React, { useEffect, useState } from 'react';
import { Layout, Table, Select,  Typography, message } from 'antd';
import { useAuth } from '../hooks/useAuth';
import axios from 'axios';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import FooterComponent from '../components/Footer/FooterComponent';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const UserManagementPage = () => {
    const { user, token } = useAuth(); // Obtém o usuário logado e o token do contexto de autenticação
    const [users, setUsers] = useState([]); // Estado para lista de usuários
    const [loading, setLoading] = useState(true); // Estado de carregamento

    useEffect(() => {
        // Função para buscar usuários do backend
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${url_base}/users`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsers(response.data);
            } catch (error) {
                message.error('Erro ao carregar a lista de usuários.');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [token]);

    // Função para alterar a role de um usuário
    const handleRoleChange = async (username, newRole) => {
        try {
            await axios.put(
                `${url_base}/users/${username}/role`,
                { role: newRole },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            message.success('Função do usuário atualizada com sucesso!');
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.username === username ? { ...user, role: newRole } : user
                )
            );
        } catch (error) {
            message.error('Erro ao atualizar a função do usuário.');
        }
    };

    // Colunas da tabela
    const columns = [
        {
            title: 'Usuário',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Função',
            dataIndex: 'role',
            key: 'role',
            render: (role, record) => (
                <Select
                    value={role}
                    onChange={(newRole) => handleRoleChange(record.username, newRole)}
                    style={{ width: 150 }}
                    disabled={record.username === user.username} // Desabilita para o usuário logado
                >
                    <Option value="user">User</Option>
                    <Option value="admin">Admin</Option>
                </Select>
            ),
        },
    ];

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content style={{ overflowY: 'auto', padding: '20px', display: 'flex', flexDirection: 'column', flex: 1 }}>

                    <div style={{ flex: 1 }}>
                        <Title level={2} >Gerenciamento de Usuários</Title>
                        <Table
                            columns={columns}
                            dataSource={users}
                            rowKey="username"
                            loading={loading}
                            pagination={{ pageSize: 10 }}
                        />
                    </div>
                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default UserManagementPage;
