import React, { useState } from 'react';
import { Layout, Form, Input, Button, message, Typography } from 'antd';
import { useAuth } from '../hooks/useAuth';
import FooterComponent from '../components/Footer/FooterComponent';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import axios from 'axios';
import {InfoCircleOutlined} from  '@ant-design/icons';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Content } = Layout;
const { Title } = Typography;

const AccountManagementPage = () => {
    const { user, token } = useAuth(); // Obtém o usuário logado e o token do contexto de autenticação
    const [loading, setLoading] = useState(false); // Estado para o botão de salvar
    const [form] = Form.useForm(); // Instância do formulário

    // Função para atualizar os dados do usuário
    const handleSave = async (values) => {
        setLoading(true);
        try {
            // Envia os dados atualizados para o backend
            await axios.put(
                `${url_base}/users/update`,
                { ...values },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            message.success('Dados da conta atualizados com sucesso!');
        } catch (error) {
            message.error('Erro ao atualizar os dados da conta.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content
                    style={{
                        overflowY: 'auto',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <div style={{ flex: 1 }}>
                        <Title level={2}>Gerenciamento de Conta</Title>
                        <Form
                            form={form}
                            layout="vertical"
                            initialValues={{
                                username: user?.username,
                                email: user?.email,
                            }}
                            onFinish={handleSave}
                            style={{ maxWidth: '400px', margin: '0 auto' }}
                        >
                            <Form.Item
                                label="Nome"
                                name="username"
                                rules={[{ required: true, message: 'Por favor, insira seu nome!' }]}
                            >
                                <Input placeholder="Digite seu nome" />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    { required: true, message: 'Por favor, insira seu email!' },
                                    { type: 'email', message: 'Digite um email válido!' },
                                ]}
                            >
                                <Input placeholder="Digite seu email" />
                            </Form.Item>

                            <Form.Item
                                label="Senha Atual"
                                name="currentPassword"
                                rules={[{ min: 8, message: 'A senha deve ter pelo menos 8 caracteres.' },]}
                            >
                                <Input.Password placeholder="Digite sua senha atual" />
                            </Form.Item>

                            <Form.Item
                                label="Nova Senha"
                                name="newPassword"
                                rules={[
                                    { min: 8,message: 'A nova senha deve ter pelo menos 8 caracteres.' },
                                    {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                        message: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
                                    }
                                ]}
                                tooltip={{ title: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.', icon: <InfoCircleOutlined /> }}

                            >
                                <Input.Password placeholder="Digite sua nova senha" />
                            </Form.Item>

                            <Form.Item
                                label="Confirme a Nova Senha"
                                name="confirmPassword"
                                dependencies={['newPassword']}
                                rules={[
                                    { min: 8, message: 'A senha deve ter pelo menos 8 caracteres.' },
                                    {
                                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
                                        message: 'A senha deve conter pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('As senhas não correspondem!'));
                                        },
                                    }),
                                ]}
                                
                            >
                                <Input.Password placeholder="Confirme sua nova senha" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    Salvar Alterações
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>

                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default AccountManagementPage;
