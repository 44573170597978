import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, Layout, Modal } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Title, Text } = Typography;
const { Content } = Layout;

const images = [
    '/Content/Images/back_1.jpeg',
    '/Content/Images/back_2.jpeg',
    '/Content/Images/back_3.jpeg',
];

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        }, 5000);

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, []);

    const handleForgotPassword = async (values) => {
        setLoading(true);
        try {
            await axios.post(`${url_base}/forgot-password`, { email: values.email });
            Modal.success({
                title: 'E-mail enviado!',
                content: 'Verifique seu e-mail para redefinir sua senha.',
            });
        } catch (error) {
            Modal.error({
                title: 'Erro',
                content: 'Não foi possível enviar o e-mail. Tente novamente mais tarde.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh', flexDirection: 'row' }}>
            <div
                style={{
                    flex: 2,
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {images.map((image, index) => (
                    <div
                        key={index}
                        style={{
                            backgroundImage: `url(${image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            opacity: currentImage === index ? 1 : 0,
                            transition: 'opacity 1s ease',
                            zIndex: currentImage === index ? 1 : 0,
                        }}
                    />
                ))}
            </div>
            <Content style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                <div style={{ width: '100%', maxWidth: '400px' }}>
                    <Title level={2} style={{ textAlign: 'center' }}>Esqueceu a Senha?</Title>
                    <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: '20px', fontSize: '18px' }}>
                        Insira seu e-mail para receber instruções de redefinição de senha.
                    </Text>

                    <Form layout="vertical" name="forgot-password-form" onFinish={handleForgotPassword}>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                { required: true, message: 'Por favor, digite seu email!' },
                                { type: 'email', message: 'Digite um email válido!' },
                            ]}
                        >
                            <Input placeholder="Digite seu email" style={{ height: '45px' }} />
                        </Form.Item>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                block 
                                style={{ height: '45px', backgroundColor: '#FB8500' }}
                                loading={loading}
                            >
                                Enviar
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
                                Lembrou-se da senha? <Link to="/login">Entrar</Link>
                            </Text>
                        </Form.Item>
                    </Form>
                </div>
            </Content>
        </Layout>
    );
};

export default ForgotPassword;
