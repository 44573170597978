import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Layout, Menu, message, Dropdown, Typography, Avatar, Space, Badge, Modal, Form, Input, List } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, BellOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import axios from 'axios';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Header } = Layout;
const { Text } = Typography;

const AppHeader = () => {
    const { user, logout, token } = useAuth();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [empName, setEmpName] = useState(localStorage.getItem('companyName') || ''); // Inicializa a partir do localStorage
    const [notifications] = useState([
        'Nova mensagem de usuário',
        'Atualização do sistema disponível',
        'Nova análise concluída',
        'Erro no servidor identificado',
        'Nova configuração salva',
    ]);
    const [isHovered, setIsHovered] = useState(false);

    // Carregar nome da empresa do backend ao montar o componente
    useEffect(() => {
        if (!empName) {
            fetchCompanyData();
        }
    }, []);

    const fetchCompanyData = async () => {
        try {
            const response = await axios.get(`${url_base}/company`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setEmpName(response.data.companyName);
            localStorage.setItem('companyName', response.data.companyName); // Salva no localStorage
        } catch (error) {
            message.error('Erro ao carregar informações da empresa.');
        }
    };

    // Handler para abrir o modal
    const handleEditClick = () => {
        setIsModalVisible(true);
    };

    // Handler para fechar o modal
    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    // Handler para atualizar o nome da empresa
    const handleSave = async (values) => {
        try {
            const response = await axios.put(`${url_base}/company`, 
                { companyName: values.username }, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            setEmpName(response.data.companyName);
            localStorage.setItem('companyName', response.data.companyName); // Salva no localStorage
            message.success('Nome da empresa atualizado com sucesso!');
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            message.error('Erro ao atualizar o nome da empresa.');
        }
    };

    // Handler para logout
    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    // Menu do usuário
    const userMenu = (
        <Menu>
            <Menu.Item key="account" icon={<UserOutlined />} onClick={() => navigate('/gerenciar-conta')}>
                Conta
            </Menu.Item>
            {user?.role === 'admin' && (
                <Menu.Item key="manage-users" icon={<SettingOutlined />} onClick={() => navigate('/gerenciar-usuarios')}>
                    Gerenciar Usuários
                </Menu.Item>
            )}
            <Menu.Item key="logout" icon={<SettingOutlined />} onClick={handleLogout}>
                Sair
            </Menu.Item>
        </Menu>
    );

    // Obtém as iniciais ou as duas primeiras letras do nome da empresa
    const getAvatarText = (name) => {
        if (!name) return '';
        return name.split(' ').map(word => word[0]).join('').substring(0, 2).toUpperCase();
    };

    // Dropdown de notificações
    const notificationsMenu = (
        <Menu style={{ padding: '20px' }}>
            <List
                dataSource={notifications}
                renderItem={(item, index) => (
                    <List.Item key={index}>{item}</List.Item>
                )}
            />
        </Menu>
    );

    return (
        <>
            <Header className="header" style={{ backgroundColor: '#fff', paddingRight: '24px', paddingLeft: '24px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Avatar size="large" style={{ backgroundColor: '#7265e6' }}>
                            {getAvatarText(empName)}
                        </Avatar>
                        <Text style={{ fontSize: '14px', color: '#888' }}>{empName}</Text>
                        <div
                            style={{
                                display: 'inline-block',
                                opacity: isHovered ? 1 : 0.2,
                                transition: 'opacity 0.3s',
                            }}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <EditOutlined
                                style={{ cursor: 'pointer', color: '#888', fontSize: '18px', margin: '0 10px' }}
                                onClick={handleEditClick}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '50px', margin: '0 20px' }}>
                        {/* Dropdown de notificações */}
                        <Dropdown overlay={notificationsMenu} trigger={['click']}>
                            <Badge count={notifications.length} offset={[10, 0]}>
                                <BellOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                            </Badge>
                        </Dropdown>

                        <Dropdown overlay={userMenu} trigger={['click']}>
                            <Space>
                                <Avatar size="large" icon={<UserOutlined />} style={{cursor: 'pointer'}}/>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Text>{user?.username}</Text>
                                    <Text style={{ fontSize: '12px', color: '#888' }}>{user?.role}</Text>
                                </div>
                            </Space>
                        </Dropdown>
                    </div>
                </div>
            </Header>

            {/* Modal para editar o nome da empresa */}
            <Modal
                title="Editar Perfil"
                visible={isModalVisible}
                onCancel={handleCancel}
                onOk={() => form.submit()}
                okText="Salvar"
                cancelText="Cancelar"
            >
                <Form form={form} layout="vertical" onFinish={handleSave}>
                    <Form.Item
                        label="Nome"
                        name="username"
                        initialValue={empName}
                        rules={[{ required: true, message: 'Por favor, insira o nome da empresa!' }]}
                    >
                        <Input placeholder="Digite o nome da empresa" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AppHeader;
