import React, { createContext, useContext, useState } from 'react';

// Criação do contexto
const FormContext = createContext();

// Hook para acessar o contexto
export const useFormContext = () => {
    return useContext(FormContext);
};

// Provider que envolve os componentes que precisam do contexto
export const FormProvider = ({ children }) => {
    const [formData, setFormData] = useState({
        sameAddress: true,
        inversores: [],
        // adicione outros estados aqui conforme necessário
    });

    const updateFormData = (key, value) => {
        setFormData(prev => ({ ...prev, [key]: value }));
    };

    return (
        <FormContext.Provider value={{ formData, updateFormData }}>
            {children}
        </FormContext.Provider>
    );
};
