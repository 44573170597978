import React, { useEffect } from 'react';
import { Layout, Form, Input, Row, Col } from 'antd';

const GridComponent = ({ form, onSave}) => {
    const handleSaveForm = () => form.getFieldsValue(); // Captura os valores do formulário

    useEffect(() => {
        if (onSave) {
            onSave(handleSaveForm); // Define a função onSave
        }
    }, [onSave]);
    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2>Grid</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nº do Cliente"
                            name="numeroCliente"
                            rules={[{ required: true, message: 'Informe o número do cliente!' }]}
                        >
                            <Input placeholder="Digite o Nº" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Transformador"
                            name="transformador"
                            rules={[{ required: true, message: 'Informe o transformador!' }]}
                        >
                            <Input placeholder="Texto" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default GridComponent;
