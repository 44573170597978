import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Radio, Select, Modal, Tag, Popconfirm, Row, Col, message, Button } from 'antd';
import { useFormContext } from './FormProvider';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Option } = Select;

const UsinaSolarForm = ({ form, handleCepChange, onSave }) => {
    const [modalForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { formData, updateFormData } = useFormContext();
    const [currentInversor, setCurrentInversor] = useState(null);
    const [usinaId, setUsinaId] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (onSave) {
            onSave(form.getFieldsValue); 
        }
    }, [onSave]);

    const showModal = (inversor = null) => {
        setIsModalVisible(true);
        if (inversor) {
            setCurrentInversor(inversor);
            modalForm.setFieldsValue(inversor);
        } else {
            setCurrentInversor(null);
            modalForm.resetFields();
        }
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        modalForm.resetFields();
        setCurrentInversor(null);
    };
    
    // Função para salvar dados do inversor no backend
    const saveInversorData = async () => {
        try {
            const values = modalForm.getFieldsValue();
            const response = await axios.post(`${url_base}/step/2.2`, values, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            message.success('Inversor salvo com sucesso!');
            handleCloseModal();
        } catch (error) {
            message.error('Erro ao salvar o inversor.');
        }
    };

    const handleSaveInversor = () => {
        modalForm.validateFields()
            .then(values => {
                const newInversor = { ...values, id: currentInversor ? currentInversor.id : Date.now() };
                
                const updatedInversores = currentInversor
                    ? formData.inversores.map(inv => inv.id === currentInversor.id ? newInversor : inv)
                    : [...formData.inversores, newInversor];
                
                updateFormData("inversores", updatedInversores);
                saveInversorData();
            })
            .catch(error => {
                const errorMsg = error.errorFields.map(field => field.errors).flat().join(', ');
                message.error(`Erro ao salvar o inversor: ${errorMsg}`);
            });
    };


    const handleDeleteInversor = (id) => {
        const updatedInversores = formData.inversores.filter(inversor => inversor.id !== id);
        updateFormData("inversores", updatedInversores);
        message.success('Inversor deletado com sucesso!');
    };
    
    const handleSameAddressChange = (e) => {
        const sameAddress = e.target.value === true;
        updateFormData("sameAddress", sameAddress);

        if (sameAddress && formData.siteAddress) {
            form.setFieldsValue({
                cepUsina: formData.siteAddress.cep || '',
                ruaUsina: formData.siteAddress.rua || '',
                bairroUsina: formData.siteAddress.bairro || '',
                complementoUsina: formData.siteAddress.complemento || '',
                numeroUsina: formData.siteAddress.numero || ''
            });
        } else {
            form.setFieldsValue({
                cepUsina: '',
                ruaUsina: '',
                bairroUsina: '',
                complementoUsina: '',
                numeroUsina: ''
            });
        }
    };

    //TODO: TERMINAR LINK ENTRE INVERSOR USINA

    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            
                <Form layout="vertical" form={form}>
                    <h2>Usina Solar</h2>
                    <Row gutter={12} align="bottom">
                        <Col span={4}>
                            <Form.Item label="Nome do Gerente" name="nomeGerente" rules={[{ required: true, message: 'Informe o nome do gerente!' }]}>
                                <Input placeholder="Nome do Gerente" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Email do Gerente" name="emailGerente" rules={[{ required: true, message: 'Informe o email!' }]}>
                                <Input placeholder="Digite o Email" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Telefone ou Celular" name="telefoneGerente" rules={[{ required: true, message: 'Informe o telefone!' }]}>
                                <Input placeholder="Digite o Número" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={24}>
                            <Form.Item label="O Endereço é o mesmo do site?" name="sameAddress">
                                <Radio.Group onChange={handleSameAddressChange}>
                                    <Radio value={true}>Sim</Radio>
                                    <Radio value={false}>Não</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12} align="bottom">
                        <Col span={4}>
                            <Form.Item label="CEP" name="cepUsina" rules={[{ required: !formData.sameAddress, message: 'Informe o CEP!' }]}>
                                <Input 
                                    placeholder="Digite o CEP" 
                                    disabled={formData.sameAddress}
                                    onChange={(e) => handleCepChange(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Rua" name="ruaUsina" rules={[{ required: !formData.sameAddress, message: 'Informe a rua!' }]}>
                                <Input placeholder="Nome da Rua" disabled={formData.sameAddress} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Bairro" name="bairroUsina" rules={[{ required: !formData.sameAddress, message: 'Informe o bairro!' }]}>
                                <Input placeholder="Nome do Bairro" disabled={formData.sameAddress} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Complemento" name="complementoUsina">
                                <Input placeholder="Digite o Complemento" disabled={formData.sameAddress} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Nº" name="numeroUsina" rules={[{ required: !formData.sameAddress, message: 'Informe o número!' }]}>
                                <Input placeholder="Digite o Nº" disabled={formData.sameAddress} />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    <h2>Informações de Equipamento</h2>

                    <Row gutter={12} align="bottom">
                        <Col span={4}>
                            <Form.Item label="kWp Total" name="kwpUsina" rules={[{ required: true, message: 'Informe a quantidade de módulos!' }]}>
                                <Input placeholder="Digite o kWp Total" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Digite a Quantidade" name="modulosUsina" rules={[{ required: true, message: 'Informe a quantidade de módulos!' }]}>
                                <Input placeholder="Digite o Nº" />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Digite a Potência" name="potenciaModuloUsina" rules={[{ required: true, message: 'Informe a quantidade de módulos!' }]}>
                                <Input placeholder="Digite a Potência" />
                            </Form.Item>
                        </Col>
                    </Row>
                    
                </Form>
                <Button 
                    type="primary" 
                    onClick={() => showModal()}
                    style={{width:'205px'}}
                >
                    + Adicionar Inversor
                </Button>
                
                
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '16px' }}>
                    {formData.inversores.map(inversor => (
                        <Tag
                            key={inversor.id}
                            color="orange"
                            onClick={() => showModal(inversor)}
                            style={{
                                cursor: 'pointer',
                                width: '205px',
                                height: '30px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontWeight: '800',
                                fontSize: '16px',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >
                            Inversor Nº {inversor.numeroSerie}
                            {/* Ícone para deletar o inversor */}
                            <Popconfirm
                                title="Tem certeza que deseja deletar este inversor?"
                                onConfirm={() => handleDeleteInversor(inversor.id)}
                                okText="Sim"
                                cancelText="Não"
                            >
                                <DeleteOutlined
                                    style={{
                                        position: 'absolute',
                                        right: '5px',
                                        top: '5px',
                                        marginLeft:'10px',
                                        fontSize: '14px',
                                        cursor: 'pointer'
                                    }}
                                    onClick={(e) => e.stopPropagation()} // Evita que o modal seja aberto ao clicar no ícone de exclusão
                                />
                            </Popconfirm>
                        </Tag>
                    ))}
                </div>
                
                <Modal
                    title={currentInversor ? "Editar Inversor" : "Adicionar Inversor"}
                    visible={isModalVisible}
                    onCancel={handleCloseModal}
                    onOk={handleSaveInversor} 
                    width={'50%'}
                >
                    <Form layout="vertical" form={modalForm}>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item label="Nome do Dispositivo" name="nomeDispositivo" rules={[{ required: true, message: 'Informe o nome do dispositivo!' }]}>
                                    <Input placeholder="Nome do Dispositivo" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Modelo do Equipamento" name="modeloEquipamento" rules={[{ required: true, message: 'Informe o modelo!' }]}>
                                    <Input placeholder="Modelo do Equipamento" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item label="Versão do Protocolo" name="versaoProtocolo">
                                    <Input placeholder="Versão do Protocolo" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Número de Série" name="numeroSerie" rules={[{ required: true, message: 'Informe o número de série!' }]}>
                                    <Input placeholder="Número de Série" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item label="Número de IP do Equipamento" name="ipEquipamento">
                                    <Input placeholder="Endereço IP do Equipamento" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Senha de Autenticação" name="senhaAutenticacao">
                                    <Input.Password placeholder="Senha de Autenticação" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12} align="bottom">
                            <Col span={12}>
                                <Form.Item label="Código Padrão da Rede" name="codigoRede">
                                    <Select placeholder="Escolha o Código">
                                        <Option value="ABNT NBR 16149">ABNT NBR 16149</Option>
                                        <Option value="Outro">Outro</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Nível da Tensão (V)" name="nivelTensao" rules={[{ required: true, message: 'Informe o nível de tensão!' }]}>
                                <Input placeholder="Digite o Nível de Tensão (V)" />
                            </Form.Item>
                            </Col>
                        </Row>
                        
                        <Col span={12}>
                            <Form.Item label="Nível da Frequência (Hz)" name="nivelFrequencia" rules={[{ required: true, message: 'Informe o nível de frequência!' }]}>
                                <Input placeholder="Digite a Frequência (Hz)" />
                            </Form.Item>
                        </Col>
                    </Form>
                </Modal>
            
        </Layout>
    );
};

export default UsinaSolarForm;
