import React, { useEffect } from 'react';
import { Layout, Form, Input, Select, Row, Col } from 'antd';

const { Option } = Select;

const Site = ({
    form,
    onSave,
    sitesExistentes,
    selectedSite,
    handleSiteChange,
    handleCepChange,
    handleNumeroChange,
}) => {
    const handleSaveForm = () => form.getFieldsValue(); 

    useEffect(() => {
        if (onSave) {
            onSave(handleSaveForm); 
        }
    }, [onSave]);
    
    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2>Site</h2>

                <Row gutter={12} align="bottom">
                    <Col span={8} >
                        <Form.Item label="Selecione Site Existente">
                            <Select
                                placeholder="Escolha um site existente"
                                value={selectedSite || undefined}
                                onChange={handleSiteChange}
                                allowClear
                            >
                                {sitesExistentes.map(site => (
                                    <Option key={site.id} value={site.id}>
                                        {site.nome}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Nome do Site"
                            name="nome"
                            rules={[{ required: true, message: 'Informe o nome do projeto!' }]}
                        >
                            <Input placeholder="Nome do Projeto" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Pessoa Responsável"
                            name="responsavel"
                            rules={[{ required: true, message: 'Informe o responsável!' }]}
                        >
                            <Input placeholder="Digite o Nome" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Telefone ou Celular"
                            name="telefone"
                            rules={[{ required: true, message: 'Informe o telefone!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cargo do Responsável"
                            name="cargo"
                            rules={[{ required: true, message: 'Escolha o cargo!' }]}
                        >
                            <Select placeholder="Escolha o Cargo">
                                <Option value="Gerente">Gerente</Option>
                                <Option value="Supervisor">Supervisor</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <h2>Endereço do Site</h2>

                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="CEP"
                            name="cep"
                            rules={[{ required: true, message: 'Informe o CEP!' }]}
                        >
                            <Input placeholder="Digite o CEP" onChange={(e) => handleCepChange(e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Rua"
                            name="rua"
                            rules={[{ required: true, message: 'Informe a rua!' }]}
                        >
                            <Input placeholder="Nome da Rua" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Bairro"
                            name="bairro"
                            rules={[{ required: true, message: 'Informe o bairro!' }]}
                        >
                            <Input placeholder="Nome do Bairro" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Cidade"
                            name="cidade"
                            rules={[{ required: true, message: 'Informe a cidade!' }]}
                        >
                            <Input placeholder="Nome da Cidade" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Complemento" name="complemento">
                            <Input placeholder="Digite o Complemento" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº"
                            name="numero"
                            rules={[{ required: true, message: 'Informe o número!' }]}
                        >
                            <Input placeholder="Digite o Nº" onChange={(e) => handleNumeroChange(e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Site;
