import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Form, Button, Steps, message, Modal } from 'antd';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import FooterComponent from '../components/Footer/FooterComponent';
import MapButton from '../components/MapComponent/MapButton';
import UsinaSolarForm from '../components/Cadastro/UsinaSolar';
import Site from '../components/Cadastro/Site';
import Bateria from '../components/Cadastro/Bateria';
import UsinaEolica from '../components/Cadastro/UsinaEolica';
import GridComponent from '../components/Cadastro/Grid';
import Carregador from '../components/Cadastro/Carregador';
import { useFormContext } from '../components/Cadastro/FormProvider';

const url_base = process.env.REACT_APP_URL_BACKEND;

const { Content } = Layout;
const { Step } = Steps;

const CadastrosPage = () => {
    const apiKey = "AIzaSyCk1CJFz2WbQ8R5c5uvJfHvmEc_LE_ylGk";
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [cepData, setCepData] = useState({ rua: 'Digite o CEP', bairro: 'Digite o número', cidade: '', numero: '' });
    const [mapDisabled, setMapDisabled] = useState(true);
    const [googleMapsUrl, setGoogleMapsUrl] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [sitesExistentes, setSitesExistentes] = useState([]); 
    const [selectedSite, setSelectedSite] = useState(''); 
    const [useSameAddress, setUseSameAddress] = useState(true);
    const [siteId, setSiteId] = useState(null);


    const { updateFormData } = useFormContext();
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchSitesExistentes();
    }, []);


    const saveStepData = async () => {
        try {
            // Valida os campos do formulário no step atual
            await form.validateFields();
            
            // Coleta os valores e adiciona siteId ao payload
            const values = form.getFieldsValue();
            const payload = { ...values, siteId }; 
    
            const response = await axios.post(`${url_base}/step/${currentStep + 1}`, payload, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            message.success('Dados salvos com sucesso!');
            console.log(`${url_base}/step/${currentStep + 1}`);
        } catch (error) {
            // Se a validação falhar, exibe uma mensagem de erro
            if (error.errorFields) {
                message.error('Preencha todos os campos obrigatórios antes de salvar.');
            } else {
                message.error('Erro ao salvar os dados.');
            }
        }
    };
    
    


    const handleNumeroChange = (value) => {
        setCepData({ ...cepData, numero: value });
        if (cepData.rua && cepData.bairro && cepData.cidade) {
            generateGoogleMapsUrl(value, cepData.rua, cepData.bairro, cepData.cidade);
        }
    };
    
    // Carregar sites existentes
    const fetchSitesExistentes = async () => {
        try {
            const response = await axios.get(`${url_base}/sites`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setSitesExistentes(response.data);
        } catch (error) {
            message.error('Erro ao carregar sites existentes.');
        }
    };

    useEffect(() => {
        const { rua, bairro, cidade, numero } = cepData;
        if (rua && bairro && cidade && numero) {
            generateGoogleMapsUrl(numero, rua, bairro, cidade);
            setMapDisabled(false);
        } else {
            setMapDisabled(true);
        }
    }, [cepData]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };
    
    // Gera a URL do Google Maps para exibição no iframe
    const generateGoogleMapsUrl = (numero, rua, bairro, cidade) => {
        const address = `${numero} ${rua}, ${bairro}, ${cidade}`;
        const url = `https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(address)}&key=${apiKey}&maptype=satellite`;
        setGoogleMapsUrl(url);
    };
    

    // Função para ir para o próximo passo
    const next = () => {
        if (currentStep === 0) {
            form.validateFields().then(() => {
                setCurrentStep(currentStep + 1);
            }).catch(info => {
                console.log('Erro de validação:', info);
            });
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    // Função para voltar ao passo anterior
    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleCepChange = async (value) => {
        const cep = value.replace('-', '');
        if (cep.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
                if (response.data && !response.data.erro) {
                    setCepData({
                        rua: response.data.logradouro,
                        bairro: response.data.bairro,
                        cidade: response.data.localidade,
                    });
                    setMapDisabled(false);
                    form.setFieldsValue({
                        rua: response.data.logradouro,
                        bairro: response.data.bairro,
                        cidade: response.data.localidade,
                    });
                    message.success('Endereço encontrado!');
                } else {
                    message.error('CEP não encontrado.');
                }
            } catch (error) {
                message.error('Erro ao buscar o CEP.');
            }
        }
    };
    
    const handlerConclusion = async () => {
        try{
            await form.validateFields()
            message.success('Cadastro concluído!')
        }catch{
            message.error('Preencha todos os campos!')
            
        };
    };

    const handleSiteChange = (value) => {
        setSelectedSite(value);
        const site = sitesExistentes.find(site => site.id === value);
        if (site) {
            setSiteId(site.id); // Armazena o siteId
            form.setFieldsValue({
                nome: site.nome,
                responsavel: site.responsavel,
                telefone: site.telefone,
                cargo: site.cargo,
                cep: site.cep,
                rua: site.rua,
                bairro: site.bairro,
                complemento: site.complemento,
                numero: site.numero,
                cidade: site.cidade
            });
            // Atualiza o formData com as informações do site selecionado
            updateFormData("siteAddress", {
                cep: site.cep,
                rua: site.rua,
                bairro: site.bairro,
                complemento: site.complemento,
                numero: site.numero,
                cidade: site.cidade
            });
            setCepData({
                rua: site.rua,
                bairro: site.bairro,
                cidade: site.cidade,
                numero: site.numero,
            });
            setMapDisabled(false); 
        } else {
            form.resetFields();
            setCepData({ rua: '', bairro: '', cidade: '', numero: '' });
            setMapDisabled(true);
        }
    };
    

    const steps = [
        { title: 'Site e Endereço do Site', content: <Site form={form} sitesExistentes={sitesExistentes} selectedSite={selectedSite} handleSiteChange={handleSiteChange} handleNumeroChange={handleNumeroChange} handleCepChange={handleCepChange} onSave={() => form.getFieldsValue()} /> }, 
        { title: 'Usina Solar', content: <UsinaSolarForm form={form} handleCepChange={handleCepChange} onSave={() => form.getFieldsValue()} /> }, 
        { title: 'Usina Eólica', content: <UsinaEolica form={form} onSave={() => form.getFieldsValue()} /> }, 
        { title: 'Bateria', content: <Bateria
            form={form}
            useSameAddress={useSameAddress}
            setUseSameAddress={setUseSameAddress}
            handleCepChange={handleCepChange}
            handleNumeroChange={handleNumeroChange} 
            onSave={() => form.getFieldsValue()} 
        /> },
        { title: 'Grid', content: <GridComponent form={form} onSave={() => form.getFieldsValue()} /> }, 
        { title: 'Carregador', content: <Carregador form={form} onSave={() => form.getFieldsValue()}  /> } 
    ];

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content style={{ overflowY: 'auto', padding: '20px', display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ flex: 1 }}>
                        <h2>Cadastro de Site</h2>
                        <Steps current={currentStep} onChange={setCurrentStep} style={{ marginBottom: '20px' }}>
                            {steps.map((step, index) => (
                                <Step key={index} title={step.title} />
                            ))}
                        </Steps>

                        <div style={{ backgroundColor:'#FFF', padding: '30px', borderRadius: '8px', marginTop: '50px' }}>
                            <div style={{ marginBottom: '20px' }}>{steps[currentStep].content}</div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding:'20px' }}>
                                {currentStep > 0 && (
                                    <Button onClick={prev} style={{ marginRight: '8px' }}>
                                        Voltar
                                    </Button>
                                )}

                                <div style={{ display: 'flex', gap: '8px' }}>
                                    {currentStep === steps.length - 1 && (
                                        <Button type="primary" onClick={handlerConclusion}>
                                            Concluir
                                        </Button>
                                    )}
                                    {currentStep < steps.length - 1 && (
                                        <>
                                            <Button onClick={next}>Próximo</Button>
                                        </>
                                    )}
                                    <Button type="primary" onClick={() => saveStepData(steps[currentStep].content.props.onSave)} style={{ marginRight: '8px' }}>
                                        Salvar
                                    </Button>
                                </div>
                            </div>

                            <div style={{ marginTop: '20px', paddingLeft: '20px',opacity: mapDisabled ? 0.7 : 1 }}>
                                <MapButton
                                    rua={cepData.rua}
                                    bairro={cepData.bairro}
                                    cidade={cepData.cidade}
                                    numero={cepData.numero}
                                    disabled={mapDisabled}
                                    style={{ width: '40%' }}
                                    onClick={showModal}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal open={isModalVisible} onCancel={handleCloseModal} footer={null} width={800}>
                        {googleMapsUrl ? (
                            <iframe src={googleMapsUrl} title="Mapa do Google" width="100%" height="550" style={{ border: 0 }} allowFullScreen loading="lazy" />
                        ) : (
                            <p>Carregando mapa...</p>
                        )}
                    </Modal>

                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default CadastrosPage;
