import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import AppRoutes from './components/Routes/AppRoutes';
import { FormProvider } from './components/Cadastro/FormProvider';
const App = () => {
    return (
        <FormProvider>
            <AuthProvider>
                <Router>
                    <AppRoutes />
                </Router>
            </AuthProvider>
        </FormProvider>
    );
};

export default App;
