import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

const ProtectedRoute = ({ children, role }) => {
    const { user } = useAuth();

    // Verifica se o usuário está autenticado e possui a role necessária
    if (!user || (role && user.role !== role)) {
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;
