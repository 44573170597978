import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <Footer style={{textAlign: 'center', color: '#757575', margin: '0.2%'}}>
            Heliosfera - SmartGRID © {new Date().getFullYear()}
        </Footer>
    );
};

export default FooterComponent;
