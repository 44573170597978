import React from 'react';
import { Layout, Result, Button } from 'antd';
import FooterComponent from '../components/Footer/FooterComponent';
import AppHeader from '../components/Header/AppHeader';
import SideMenu from '../components/SideMenu/SideMenu';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;

const ConfiguracoesPage = () => {
    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate('/mapa-de-sites'); 
    };

    return (
        <Layout style={{ height: '100vh' }}>
            <SideMenu style={{ position: 'fixed', zIndex: 1 }} />
            <Layout>
                <AppHeader style={{ position: 'fixed', width: '100%', zIndex: 1, top: 0 }} />
                <Content
                    style={{
                        overflowY: 'auto',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                    }}
                >
                    <div style={{ flex: 1 }}>
                    <Result
                        status="403"
                        title="403"
                        subTitle="Desculpa, Página em Desenvolvimento."
                        extra={<Button type="primary" onClick={handleBackToLogin}>Página Inicial</Button>}
                    />
                    </div>

                    <FooterComponent />
                </Content>
            </Layout>
        </Layout>
    );
};

export default ConfiguracoesPage;
