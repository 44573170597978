import React, { useEffect } from 'react';
import { Layout, Form, Input, Select, Row, Col } from 'antd';

const { Option } = Select;

const Carregador = ({ form, onSave }) => {
    const handleSaveForm = () => form.getFieldsValue(); // Captura os valores do formulário

    useEffect(() => {
        if (onSave) {
            onSave(handleSaveForm); // Define a função onSav
        }
    }, [onSave]);
    return (
        <Layout style={{ padding: '20px', backgroundColor: 'white' }}>
            <Form layout="vertical" form={form}>
                <h2>Carregador</h2>
                <Row gutter={12} align="bottom">
                    <Col span={4}>
                        <Form.Item
                            label="Quantidade de Carregadores"
                            name="quantidadeCarregadores"
                            rules={[{ required: true, message: 'Informe a quantidade!' }]}
                        >
                            <Select placeholder="Escolha a Quantidade">
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                                <Option value="3">3</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Nº de Série do Carregador"
                            name="numeroSerie"
                            rules={[{ required: true, message: 'Informe o número de série!' }]}
                        >
                            <Input placeholder="Digite o Número" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Escolha a Potência"
                            name="potencia"
                            rules={[{ required: true, message: 'Escolha a potência!' }]}
                        >
                            <Select placeholder="Escolha a Potência">
                                <Option value="7.4kW">7.4 kW</Option>
                                <Option value="11kW">11 kW</Option>
                                <Option value="22kW">22 kW</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Layout>
    );
};

export default Carregador;
